<template>
  <div class="installment-order">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <!-- MAIN CONTAINER -->
    <b-row>
      <b-col md="12" lg="12" sm="12">
        <KTCodePreview v-bind:title="' '">
          <template v-slot:toolbar>
            <div class="row">
              <div>
                <b-dropdown size="sm" id="dropdown-1" right>
                  <template slot="button-content">
                    <i style="font-size: 1rem" class="fas fa-cog"></i>
                    <span class="font-weight-bolder">Thao tác</span>
                  </template>
                  <b-dropdown-item @click="showModalExcel">
                    <span>
                      <i style="font-size: 1rem" class="far fa-file-excel"></i>
                      &nbsp; Xuất Excel</span
                    >
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </template>
          <!-- CONTENT -->
          <template v-slot:preview>
            <!-- FILTER CONTENT -->
            <b-row class="mb-5">
              <b-col>
                <b-form>
                  <Autosuggest
                    :model="searchStock"
                    :suggestions="filteredOptions"
                    placeholder="cửa hàng"
                    :limit="10"
                    @select="onSelectedStock"
                    @change="onInputChangeStore"
                    :disabled="isDisabled"
                  />
                </b-form>
              </b-col>
              <b-col>
                <b-input-group>
                  <div class="date-style">
                    <b-input-group>
                      <date-picker
                        placeholder="Từ"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="apiParams.fromDate"
                      ></date-picker>
                    </b-input-group>
                    <span class="ml-1 mr-1"></span>
                    <b-input-group>
                      <date-picker
                        placeholder="Đến"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="apiParams.toDate"
                      ></date-picker>
                    </b-input-group>
                  </div>
                </b-input-group>
              </b-col>
              <b-col>
                <b-form>
                  <BDropdownCustom
                    v-model="apiParams.contactId"
                    :options="filteredInstallment"
                    :placeholder="'dịch vụ trả góp'"
                    :textProp="'text'"
                    :matchKey="['text']"
                    :limit="5"
                    :searchable="true"
                  />
                </b-form>
              </b-col>
              <b-col>
                <b-input
                  placeholder="Mã HĐ"
                  v-model="apiParams.contractCode"
                  size="sm"
                />
              </b-col>
              <b-col>
                <b-input
                  placeholder="Chứng từ"
                  v-model="apiParams.docId"
                  size="sm"
                />
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <b-form>
                  <b-input
                    placeholder="Tìm chính xác SĐT KH"
                    v-model="apiParams.customerMobile"
                    size="sm"
                  />
                </b-form>
              </b-col>
              <b-col>
                <b-form>
                  <b-form-select
                    v-model="apiParams.isFullPaid"
                    :options="optionPaidStatus"
                    size="sm"
                    class="select-style"
                  ></b-form-select>
                </b-form>
              </b-col>
              <b-col>
                <BDropdownCustom
                  v-model="apiParams.status"
                  :options="filteredStatus"
                  :placeholder="'trạng thái'"
                  :textProp="'text'"
                  :matchKey="['text']"
                  :limit="5"
                  :searchable="true"
                />
              </b-col>
              <b-col>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="onFilter()"
                  >Lọc</b-button
                >
              </b-col>
              <b-col></b-col>
            </b-row>
            <!-- LIST DATA -->
            <b-row>
              <b-col cols="12">
                <b-table-simple bordered hover class="tbody-hover">
                  <b-thead>
                    <b-tr>
                      <b-th class="text-center tdDate">Ngày</b-th>
                      <b-th class="text-center tdStore">Cửa hàng</b-th>
                      <b-th class="text-center tdCustomer">Khách hàng</b-th>
                      <b-th class="text-center tdProductName"
                        >Dịch vụ trả góp</b-th
                      >
                      <b-th class="text-center tdProductPrice"
                        >Mã hợp đồng</b-th
                      >
                      <b-th class="text-center tdProductPrice"
                        >Tiền trả góp</b-th
                      >
                      <b-th class="text-center tdTotalPayment"
                        >Đã thanh toán</b-th
                      >
                      <b-th class="text-center tdTotalPayment">Còn lại</b-th>
                      <b-th class="text-center tdTotalAmount"
                        >Tổng tiền hoá đơn</b-th
                      >
                      <b-th class="text-center"></b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody v-for="bill in mainList.dataset" :key="bill.id">
                    <b-tr v-for="(item, index) in bill.payments" :key="index">
                      <b-td
                        :rowspan="bill.payments.length + 1"
                        class="tdDate"
                        v-show="isShowRow(bill.payments, item)"
                      >
                        {{ formatDate(bill.createdAt) }}
                        <div>
                          <router-link
                            :to="{
                              path: `/orders/edit-order/${bill.id}`,
                            }"
                            target="_blank"
                            >{{ bill.id }}
                          </router-link>
                        </div>
                      </b-td>
                      <b-td
                        :rowspan="bill.payments.length + 1"
                        class="text-left tdStore"
                        v-show="isShowRow(bill.payments, item)"
                      >
                        {{ bill.storeName }}
                      </b-td>
                      <b-td
                        :rowspan="bill.payments.length + 1"
                        class="text-left tdCenter tdCustomer"
                        v-show="isShowRow(bill.payments, item)"
                      >
                        <b> {{ bill.customerName }}</b>
                        <p>{{ bill.customerMobile }}</p>
                      </b-td>
                      <b-td class="text-left tdProductName">
                        <b> {{ item.paymentName }}</b>
                      </b-td>
                      <b-td class="text-right tdProductPrice">
                        {{ item.paymentCode }}
                      </b-td>
                      <b-td class="text-right tdProductPrice">
                        {{ convertPrice(item.paymentAmount) }}
                      </b-td>
                      <b-td
                        :rowspan="bill.payments.length + 1"
                        class="text-right tdCenter"
                        v-show="isShowRow(bill.payments, item)"
                        style="width: 10%; color: #3c763d; font-weight: 700"
                      >
                        {{ convertPrice(bill.sumAmountTransactions) }}
                      </b-td>
                      <b-td
                        :rowspan="bill.payments.length + 1"
                        class="text-right tdCenter"
                        v-show="isShowRow(bill.payments, item)"
                        style="width: 10%; color: #3c763d; font-weight: 700"
                      >
                        {{ convertPrice(bill.sumRemainTransaction) }}
                      </b-td>
                      <b-td
                        :rowspan="bill.payments.length + 1"
                        class="text-right tdCenter"
                        v-show="isShowRow(bill.payments, item)"
                        style="width: 10%; color: #3c763d; font-weight: 700"
                      >
                        {{ convertPrice(bill.totalAmount) }}
                      </b-td>
                      <b-td class="text-center" style="width: 5%">
                        <b-dropdown
                          size="sm"
                          id="dropdown-left"
                          right
                          no-caret
                          v-if="bill.sumRemainTransaction > 0"
                        >
                          <template slot="button-content">
                            <i
                              style="font-size: 1rem; padding-right: 0px"
                              class="flaticon2-settings"
                            ></i>
                          </template>
                          <b-dropdown-item
                            o-caret
                            @click="editModalPayment(bill)"
                          >
                            <span style="color: #3f4254; font-size: 12px">
                              <i
                                class="flaticon2-pen"
                                style="font-size: 1rem"
                              ></i>
                              &nbsp; Chỉnh sửa
                            </span>
                          </b-dropdown-item>
                          <b-dropdown-item
                            o-caret
                            @click="linkToTransaction(bill, item)"
                          >
                            <span style="color: #3f4254; font-size: 12px">
                              <i
                                style="font-size: 1rem"
                                class="far fa-money-bill-alt"
                              ></i>
                              &nbsp; Thanh toán bằng tiền mặt
                            </span>
                          </b-dropdown-item>
                          <b-dropdown-item>
                            <router-link
                              :to="{
                                name: 'transaction',
                                params: {
                                  mainModelProp: {
                                    contactType: '3',
                                    reconciliationDocType: '2',
                                    reconciliationDocId: bill.id,
                                    type: '3',
                                    contactId: item.paymentRefId,
                                    contactName: item.paymentName,
                                    amount: item.paymentAmount,
                                    note: getNoteForPay(bill, item),
                                  },
                                  mode: 'bank',
                                  action: 'add',
                                  id: 'new',
                                },
                              }"
                            >
                              <span style="color: #3f4254; font-size: 12px">
                                <i
                                  style="font-size: 1rem"
                                  class="fas fa-money-check-alt"
                                ></i>
                                &nbsp; Thanh toán bằng CK ngân hàng
                              </span>
                            </router-link>
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                  <b-tfoot>
                    <tr>
                      <td colspan="5" class="text-right">
                        <b>Tổng</b>
                      </td>
                      <td class="text-right">
                        <b>
                          {{
                            sumTotalAmount(
                              'sumAmountPayments',
                              mainList.dataset
                            )
                          }}</b
                        >
                      </td>
                      <td class="text-right">
                        <b>
                          {{
                            sumTotalAmount(
                              'sumAmountTransactions',
                              mainList.dataset
                            )
                          }}</b
                        >
                      </td>
                      <td class="text-right">
                        <b>
                          {{
                            sumTotalAmount(
                              'sumRemainTransaction',
                              mainList.dataset
                            )
                          }}
                        </b>
                      </td>
                      <td class="text-right">
                        <b>
                          {{ sumTotalAmount('totalAmount', mainList.dataset) }}
                        </b>
                      </td>
                      <td></td>
                    </tr>
                  </b-tfoot>
                </b-table-simple>
                <vcl-table
                  v-if="onLoadingList"
                  :speed="5"
                  :animate="true"
                  :columns="11"
                />
              </b-col>
            </b-row>
            <!-- PAGINATION -->
            <b-row>
              <b-col lg="3" md="3" sm="12">
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số:
                  {{ mainList.total }}
                </p>
              </b-col>
              <b-col lg="9" md="9" sm="12">
                <b-pagination-nav
                  class="customPagination"
                  v-show="mainList.pages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="mainList.pages"
                  use-router
                  @change="fetchMainData"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm m-1"
                  prev-class="page-item-prev btn btn-icon btn-sm m-1"
                  next-class="page-item-next btn btn-icon btn-sm m-1 "
                  last-class="page-item-last btn btn-icon btn-sm m-1 "
                  page-class="btn btn-icon btn-sm border-0 m-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
      </b-col>
    </b-row>
    <DebtEditModal
      :item="order"
      :mode="mbPaymentType"
      v-on:refresh="fetchMainData"
    />
    <template>
      <DebtExcel :param="apiParams" :mode="mbPaymentType" />
    </template>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import { getToastInstance } from '@/utils/toastHelper';
import { mapGetters } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import { checkPermissions } from '@/utils/saveDataToLocal';
import DebtEditModal from '@/view/components/debt/DebtEditModal.vue';
import { getSubtractDate, getAddDate } from '@/utils/date';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import DebtExcel from '../../components/debt/DebtExcel.vue';

import {
  checkedOptions,
  cloneDeep,
  removeAccents,
  convertPrice,
} from '@/utils/common';
import { ORDER_STATUS } from '@/utils/constants';
import timeUtils from '@/utils/date';
import ENUM from '@/utils/enum';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';
const MIN_RANGE_DATE = getSubtractDate(7);
const MAX_RANGE_DATE = getAddDate();

export default {
  // Data of view.
  data() {
    return {
      title: 'Công nợ trả góp đơn hàng theo ngày',
      listStores: [{ value: null, text: 'Tất cả cửa hàng' }],
      optionInstallment: [],
      optionPaidStatus: [
        { value: null, text: 'Công nợ trả góp' },
        { value: 'true', text: 'Hết nợ' },
        { value: 'false', text: 'Còn nợ' },
      ],
      mainList: {
        dataset: [],
        total: 0,
        pages: 1,
      },
      apiParams: {
        page: 1,
        pageSize: 10,
        storeId: '',
        fromDate: MIN_RANGE_DATE,
        toDate: MAX_RANGE_DATE,
        contactId: [],
        contractCode: null,
        docId: null,
        isFullPaid: null,
        status: [],
      },
      onLoadingList: false,
      dpConfigs: null,
      filteredOptions: [],
      optionsStore: [],
      searchStock: '',
      isDisabled: false,
      btnDisable: false,
      order: {
        orderId: null,
      },
      mbPaymentType: null,
      optionStatus: [],
      initStatus: [],
      statusDefault: [ENUM.ORDER_STATUS.CONFIRMED, ENUM.ORDER_STATUS.SUCCESS],
      filteredStatus: [],
      filteredInstallment: [],
    };
  },
  // Component defination.
  components: {
    KTCodePreview,
    Loader,
    VclTable,
    Autosuggest,
    DebtEditModal,
    DebtExcel,
    BDropdownCustom
  },
  // Mounted view.
  mounted() {
    window.addEventListener('keyup', this.onFilterKeyEnter);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onFilterKeyEnter);
  },
  created() {
    this.initConstant();
    this.getFillter();

    this.loadBreadcum();
    this.fetchInstallments();
    this.fetchStoreByUser();
    this.onFilter();
  },
  // Created view.
  methods: {
    convertPrice,
    ...getToastInstance(),
     showModalExcel() {
      this.mbPaymentType = 'installment-orders';
      this.$bvModal.show('modal-debt-excel');
    },
    linkToTransaction(bill, item) {
      this.$router.push({
        name: 'transaction',
        params: {
          mainModelProp: {
            contactType: '3',
            reconciliationDocType: '2',
            reconciliationDocId: bill.id,
            type: '1',
            contactId: item.paymentRefId,
            contactName: item.paymentName,
            amount: item.paymentAmount,
            note: this.getNoteForPay(bill, item),
          },
          mode: 'cash',
          action: 'add',
          id: 'new',
        },
      });
    },
    isShowRow: function (listData, item) {
      return _.findIndex(listData, ['id', item.id]) === 0;
    },
    initConstant() {
      this.dpConfigs = timeUtils.DP_CONFIG;

      this.initStatus = cloneDeep(ORDER_STATUS).reduce((result, current) => {
        if (this.statusDefault.includes(Number(current.value))) {
          result.push(current);
        }
        return result;
      }, []);

      this.optionStatus = this.getStatus();

      this.optionStatus.map((status) => {
        if (this.statusDefault.includes(Number(status.value))) {
          status.checked = true;
        }
      });
      this.filteredStatus = cloneDeep(this.optionStatus);
      this.apiParams.status = this.initStatus;
    },
    onSelected(option, type, value) {
      switch (type) {
        case 'Status':
          checkedOptions(this.filteredStatus, option, 'value', value);
          break;
        case 'Contact':
          checkedOptions(this.filteredInstallment, option, 'value', value);
          break;
      }
    },
    onInputed(textInput = '', type) {
      switch (type) {
        case 'Status':
          this.searchStatus(textInput);
          break;
        case 'Contact':
          this.searchContact(textInput);
          break;
        default:
          break;
      }
    },
    searchContact(textInput) {
      let options = cloneDeep(this.optionInstallment);
      if (!textInput || !textInput.trim().length) {
        this.filteredInstallment = options.map((o) => {
          const finded = this.filteredInstallment.find(
            (s) => s.value === o.value
          );
          return Object.assign(o, finded);
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.value === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = options.map((obj) => {
        const finded = this.filteredInstallment.find(
          (status) => status.value === obj.value
        );
        return Object.assign(obj, finded);
      });
      this.filteredInstallment = this.fitlerOptionsBy(
        options,
        textInput,
        'text',
        10
      );
    },
    searchStatus(textInput) {
      let options = cloneDeep(this.optionStatus);
      if (!textInput || !textInput.trim().length) {
        this.filteredStatus = options.map((o) => {
          const finded = this.filteredStatus.find((s) => s.value === o.value);
          return Object.assign(o, finded);
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.value === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = options.map((obj) => {
        const finded = this.filteredStatus.find(
          (status) => status.value === obj.value
        );
        return Object.assign(obj, finded);
      });
      this.filteredStatus = this.fitlerOptionsBy(
        options,
        textInput,
        'text',
        10
      );
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    getStatus() {
      return cloneDeep(ORDER_STATUS.filter((item) => !item.checked));
    },
    onFilterKeyEnter(event) {
      if (event.key === 'Enter') {
        this.onFilter();
      }
    },
    linkGen(pageNum) {
      let param = this.getParamFilters();
      param = _.omitBy(param, _.isNil);
      param.page = pageNum || 1;
      return {
        path: '/accounting/debts/installment-orders',
        query: param,
      };
    },
    onFilter() {
      this.onChangeUrl();
      this.fetchMainData();
    },
    onChangeUrl() {
      this.$route.query.page = 1;

      let param = this.setFillter();
      param = _.omit(param, ['page', 'pageSize']);
      this.$router.push({
        path: `/accounting/debts/installment-orders`,
        query: _.omitBy(param, _.isNil),
      });
    },
    setFillter() {
      const param = this.getParamFilters();
      param.status = param.status.map((s) => s.value);
      param.contactId = param.contactId.map((s) => s.value);
      return param;
    },
    getParamFilters() {
      return {
        page: this.apiParams.page,
        pageSize: this.apiParams.pageSize,
        storeId: this.apiParams.storeId,
        contactId: this.apiParams.contactId || [],
        fromDate: this.apiParams.fromDate
          ? moment(this.apiParams.fromDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
          : null,
        toDate: this.apiParams.toDate
          ? moment(this.apiParams.toDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
          : null,
        contractCode: this.apiParams.contractCode,
        docId: this.apiParams.docId,
        customerMobile: this.apiParams.customerMobile,
        isFullPaid: this.apiParams.isFullPaid || null,
        status: this.apiParams.status || [],
      };
    },
    getFillter() {
      const query = this.$route.query || null;

      if (!_.isEmpty(query)) {
        const storeId = query.searchStock || null;
        const fromDate = query.fromDate || null;
        const toDate = query.toDate || null;

        const contactFromUrl = query.contactId
          ? query.contactId.map((s) => Number(s))
          : [];
        const contactDistinct = contactFromUrl;

        this.filteredInstallment.map((option) => {
          if (contactFromUrl.includes(Number(option.value))) {
            option.checked = true;
          }
        });

        const contact = cloneDeep(this.optionInstallment).reduce(
          (result, current) => {
            if (contactDistinct.includes(Number(current.value))) {
              current.checked = true;
              result.push(current);
            }
            return result;
          },
          []
        );

        const contractCode = query.contractCode || null;
        const docId = query.docId || null;

        const customerMobile = query.customerMobile || null;
        const isFullPaid = query.isFullPaid || null;

        const statusFromUrl = query.status
          ? query.status.map((s) => Number(s))
          : [];
        const statusDistinct = this.statusDefault.concat(statusFromUrl);

        this.filteredStatus.map((option) => {
          if (statusFromUrl.includes(Number(option.value))) {
            option.checked = true;
          }
        });

        const status = cloneDeep(ORDER_STATUS).reduce((result, current) => {
          if (statusDistinct.includes(Number(current.value))) {
            current.checked = true;
            result.push(current);
          }
          return result;
        }, []);

        const page = query.page || 1;
        const pageSize = query.pageSize || 10;

        this.apiParams = Object.assign(
          {},
          {
            page,
            pageSize,
            storeId,
            fromDate,
            toDate,
            contractCode,
            docId,
            customerMobile,
            isFullPaid,
            status,
            contactId: contact,
          }
        );
      }
    },
    editModalPayment: function (item) {
      this.mbPaymentType = 'installment-orders';
      this.order.orderId = item.id;
      this.$bvModal.show('md-debt');
    },
    fetchMainData: async function () {
      let page = this.$route.query.page || 1;
      if (!page) {
        page = 1;
      }
      this.apiParams.page = page;
      this.apiParams.type = this.$route.params.mode;
      if (this.searchStock.trim() === '') {
        this.apiParams.storeId = null;
      }
      const params = cloneDeep(this.apiParams);
      params.status = params.status.map((s) => s.value);

      params.contactId = params.contactId.map((s) => s.value);
      // Init request header.
      this.onLoadingList = true;
      ApiService.query(cmdUrl.DebtUrl.installmentOrders, {
        params: params,
      })
        .then((response) => {
          const respData = response.data.data;
          this.mainList = respData;
          this.onLoadingList = false;
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
          this.onLoadingList = false;
        });
    },
    formatDate: function (dateVal) {
      const date = moment(dateVal);
      return date.format('DD/MM/yyyy');
    },
    loadBreadcum: function () {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Kế toán', route: 'accountant' },
        { title: 'Công nợ' },
        { title: this.title },
      ]);
    },
    fetchInstallments: async function () {
      let params = {
        page: 1,
        pageSize: 1000,
      };
      ApiService.query('/installments', { params }).then((response) => {
        const listData = response.data.data.result;
        console.log('listDataConsole', listData);
        _.map(listData, (item) => {
          this.optionInstallment.push({
            value: item.id,
            text: item.name,
            shortName: item.code,
          });
          this.filteredInstallment = cloneDeep(this.optionInstallment);
        });
      });
    },
    sumAmount(key) {
      const outItems = this.mainList.dataset;
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item[key]);
        },
        0
      );
    },
    getNoteForPay: function (bill, item) {
      let note = `Nhận thanh toán từ dịch vụ trả góp\n`;
      note += `Mã HĐ: ${bill.id}\n`;
      note += `Dịch vụ: ${item.paymentName}\n`;
      note += `Mã hợp đồng: ${item.paymentCode || ''}`;
      return note;
    },
    isShow(conditions) {
      return checkPermissions(conditions);
    },
    onSelectedStock(option) {
      this.searchStock = option.item.name;
      this.apiParams.storeId = option.item.id;
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsStore
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, 10);

      this.filteredOptions = [...filteredData];
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.searchStock = stores[0].name;
            this.apiParams.storeId = stores[0].id;
            this.isDisabled = true;
          } else {
            this.optionsStore = [];
            const data = {
              id: '',
              name: 'Tất cả cửa hàng',
              shortName: 'Tat ca cua hang',
            };
            this.optionsStore.push(data);
            stores.map((element) => {
              this.optionsStore.push(element);
            });
            this.filteredOptions = [...this.optionsStore];
            this.searchStock = data.name;
            this.apiParams.storeId = data.id;
          }
        }
        this.fetchMainData();
      });
    },
    sumTotalAmount(field, bills) {
      let sumAmount = _.sumBy(bills, (bill) => bill[field]);
      return convertPrice(sumAmount);
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
  beforeRouteUpdate: function (to, from, next) {
    this.mode = to.params.mode;
    next();
  },
  watch: {
    mode: function (val) {
      this.mode = val;
      this.fetchMainData();
      this.loadBreadcum();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.installment-order {
  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 20px;
  }

  .date-style {
    display: flex;
    align-items: center;
  }

  .pre-line {
    white-space: pre-line;
  }
  .multiselect__checkbox_name {
    display: block;
    overflow: hidden;
  }

  .multiselect__checkbox {
    vertical-align: middle;
    float: right;
  }
  .multiselect__tags {
    height: calc(1.35em + 1.1rem + 2px);
    display: block;
    border-radius: 0.28rem;
    border: 1px solid #ced4da;
    font-size: 0.925rem;
    min-height: initial;
    padding-top: 0.3rem;
  }

  .multiselect__option {
    padding: 5px;
    padding-top: 8px;
    min-height: 30px;
    white-space: pre-line;
  }

  .multiselect__select {
    width: 3rem;
    height: 2.5rem;
    top: 0.1rem;
  }

  .multiselect__placeholder {
    color: #495057;
    padding-left: 0.2rem;
  }

  .multiselect__element {
    font-size: 0.925rem;
  }

  .multiselect__input {
    padding-top: 0.2rem;
    font-size: 0.925rem;
  }

  .multiselect__single {
    font-size: 0.925rem;
    line-height: 1.9;
    padding-left: 0.2rem;
    display: inline-block;
    max-width: 100%;
  }
}
</style>